import React from 'react'
import Layout from '../components/layout'
import MapContainer from '../components/Map'
import Spacer from '../components/uielements/Spacer'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import InnerTitle from '../components/uielements/InnerTitle'
import MainTitle from '../components/uielements/MainTitle'
import Wrapper from '../components/Wrapper'

const RelativeBox = styled.div`
    display: block;
    position: relative;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
    height: 500px;
`

const ContactPage = ({ data }) => {
    const { t } = useTranslation()

    return (
        <Layout>
            <Wrapper>
                <InnerTitle>{t('find-us')}</InnerTitle>
                <Spacer top="4" />
                <MainTitle>{t('address-field')}</MainTitle>
                <Spacer top="2" />
                <RelativeBox className="klm1">
                    <MapContainer
                        lat="37.03595496718438"
                        lng="22.111442016694"
                    />
                </RelativeBox>
                <Spacer top="4" />
                <MainTitle>{t('address-field-1')}</MainTitle>
                <Spacer top="2" />
                <RelativeBox className="klm2">
                    <MapContainer
                        lat="37.03613674279566"
                        lng="22.113754801484912"
                    />
                </RelativeBox>
                <Spacer top="4" />
                <MainTitle>{t('address-field-2')}</MainTitle>
                <Spacer top="2" />
                <RelativeBox className="messini">
                    <MapContainer
                        lat="37.04595511415065"
                        lng="22.005737896337713"
                    />
                </RelativeBox>
                <Spacer top="2" />
            </Wrapper>
        </Layout>
    )
}

export default ContactPage
